let refreshServerCommand = {

    init: function() {

        document.addEventListener('DOMContentLoaded', function () {
            let refreshButtons = document.querySelectorAll('.refresh-server-ref');
            refreshButtons.forEach(function (button) {
                button.addEventListener('click', function (event) {
                    event.preventDefault();
                    let href = this.getAttribute('href');

                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', href, true);
                    xhr.onreadystatechange = function() {
                        if (xhr.readyState === XMLHttpRequest.DONE) {
                            if (xhr.status === 200) {
                                // Обработка успешного запроса
                                location.reload();
                            } else {
                                // Обработка ошибки
                                console.error('Ошибка: ' + xhr.status);
                            }
                        }
                    };
                    xhr.send();

                });
            });
        });

    }
};

refreshServerCommand.init();


