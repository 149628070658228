import '../libs/slick/slick';
let $ = jQuery = require( 'jquery' );
require( 'datatables.net-bs' );

import './refreshServerCommand';



let dataTable = null;

/**
 * Собирает выбранные строки в таблице DataTable
 */
function collectSelection()
{
	let idArray = [];

	if (dataTable !== null) {
		let checkedRows = dataTable.rows({selected: true});
		if (checkedRows.count() > 0) {
			checkedRows = checkedRows[0];
			let $rows = $(".dataTables_wrapper table.dataTable tbody tr");
			checkedRows.forEach(function (item) {
				idArray.push($rows.eq(item).find("td .datatable-row-id").data('id'));
			});
		}
	}
	return idArray;
}

// Считаем полную сумму услуг для продления
function countAllProlongPrice()
{
	let fullCost = 0;
	let count = 0;
	if ($('.prolong-table').length !== 0) {
		$('.prolong-table .prolong-control').each(function () {
			if ($(this).prop('checked')) {
				fullCost += $(this).data('price');
				count++;
			}
		});

		if ($('.free-discount').length !== 0) {
			fullCost = fullCost - $('.free-discount').data('free_discount');
		}

		if (count) {
			if (fullCost <= 0) {
				fullCost = 0;
				$('.paid-prolong').hide();
				$('.free-prolong').show();
				$('.free-prolong input').prop('checked', true);
			} else {
				if ($('.paid-prolong:visible').length === 0) {
					$('.paid-prolong').show();
					$('.free-prolong').hide();
					$('.paid-prolong input').eq(0).prop('checked', true);
				}
			}
			$('.send-payment-request').show();
			$('.prolong-method').show();

		} else {
			$('.paid-prolong').hide();
			$('.free-prolong').hide();

			$('.send-payment-request').hide();
			$('.prolong-method').hide();
		}
		$('.prolong-table .all-price').html(fullCost);
	}

	if ($('.admin-prolong-link span').length !== 0) {
		$('.admin-prolong-link span').html('prolong-payment-page' + getProlongLink());
	}

	return fullCost;
}



let ProfileForm = {
	init: function() {
		if ($('form[name=profile_form]').length) {
			this.switchType();
			$('form[name=profile_form] #profile_form_profileType').change(this.switchType);
		}
	},

	switchType: function()
	{
		let type = $('form[name=profile_form] #profile_form_profileType').val()
		if (type === 'PHYS') {
			$('#profile_form_orgName').closest('div').hide();
			$('#profile_form_inn').closest('div').hide();
			$('#profile_form_kpp').closest('div').hide();
			$('#profile_form_ogrn').closest('div').hide();
			$('#profile_form_address').closest('div').hide();
			$('#profile_form_rs').closest('div').hide();
			$('#profile_form_bank').closest('div').hide();
			$('#profile_form_bik').closest('div').hide();
			$('#profile_form_ks').closest('div').hide();
		}
		if (type === 'IP') {
			$('#profile_form_orgName').closest('div').show();
			$('#profile_form_inn').closest('div').show();
			$('#profile_form_kpp').closest('div').hide();
			$('#profile_form_ogrn').closest('div').show();
			$('#profile_form_address').closest('div').show();
			$('#profile_form_rs').closest('div').show();
			$('#profile_form_bank').closest('div').show();
			$('#profile_form_bik').closest('div').show();
			$('#profile_form_ks').closest('div').show();
			$('label[for=profile_form_ogrn]').html('ОГРНИП');
		}
		if (type === 'UR') {
			$('#profile_form_orgName').closest('div').show();
			$('#profile_form_inn').closest('div').show();
			$('#profile_form_kpp').closest('div').show();
			$('#profile_form_ogrn').closest('div').show();
			$('#profile_form_address').closest('div').show();
			$('#profile_form_rs').closest('div').show();
			$('#profile_form_bank').closest('div').show();
			$('#profile_form_bik').closest('div').show();
			$('#profile_form_ks').closest('div').show();
			$('label[for=profile_form_ogrn]').html('ОГРН');
		}
	}
}



let UserProfileForm = {
	init: function() {
		if ($('form[name=user_profile_form]').length) {
			this.switchType();
			$('form[name=user_profile_form] #user_profile_form_profileType').change(this.switchType);
		}
	},

	switchType: function()
	{
		let type = $('form[name=user_profile_form] #user_profile_form_profileType').val()
		if (type === 'PHYS') {
			$('#user_profile_form_orgName').closest('div').hide();
			$('#user_profile_form_inn').closest('div').hide();
			$('#user_profile_form_kpp').closest('div').hide();
			$('#user_profile_form_ogrn').closest('div').hide();
			$('#user_profile_form_address').closest('div').hide();
			$('#user_profile_form_rs').closest('div').hide();
			$('#user_profile_form_bank').closest('div').hide();
			$('#user_profile_form_bik').closest('div').hide();
			$('#user_profile_form_ks').closest('div').hide();
		}
		if (type === 'IP') {
			$('#user_profile_form_orgName').closest('div').show();
			$('#user_profile_form_inn').closest('div').show();
			$('#user_profile_form_kpp').closest('div').hide();
			$('#user_profile_form_ogrn').closest('div').show();
			$('#user_profile_form_address').closest('div').show();
			$('#user_profile_form_rs').closest('div').show();
			$('#user_profile_form_bank').closest('div').show();
			$('#user_profile_form_bik').closest('div').show();
			$('#user_profile_form_ks').closest('div').show();
			$('label[for=user_profile_form_ogrn]').html('ОГРНИП');
		}
		if (type === 'UR') {
			$('#user_profile_form_orgName').closest('div').show();
			$('#user_profile_form_inn').closest('div').show();
			$('#user_profile_form_kpp').closest('div').show();
			$('#user_profile_form_ogrn').closest('div').show();
			$('#user_profile_form_address').closest('div').show();
			$('#user_profile_form_rs').closest('div').show();
			$('#user_profile_form_bank').closest('div').show();
			$('#user_profile_form_bik').closest('div').show();
			$('#user_profile_form_ks').closest('div').show();
			$('label[for=user_profile_form_ogrn]').html('ОГРН');
		}
	}
}





$(document).ready(function () {

	ProfileForm.init();
	UserProfileForm.init();

	$('.set-ns-servers').click(function() {
		let $nsSelector = $('.ns-servers');
		$nsSelector.find('[name=ns1]').val('ns1.flexy-reg.ru');
		$nsSelector.find('[name=ns2]').val('ns2.flexy-reg.ru');
		$nsSelector.find('[name=ns3]').val('ns3.flexy-reg.ru');
		$nsSelector.find('[name=ip1]').val('');
		$nsSelector.find('[name=ip2]').val('');
		$nsSelector.find('[name=ip3]').val('');
		return false;
	});

	countAllProlongPrice();
	$('.prolong-table .prolong-control').change(countAllProlongPrice);
	$('.prolong-method input').change(countAllProlongPrice);

	$(".send-payment-request").click(function() {
		$.get('/prolong-payment' + getProlongLink(), [], function(result) {

			if (result.data.error) {
				alert(result.data.message);
			} else {

				if (result.data.action === 'alert') {
					alert(result.data.message);
				}
				if (result.data.action === 'console') {
					console.log(result.data.message);
				}
				if (result.data.action === 'redirect') {
					document.location = result.data.url;
				}
			}
		}, 'json');

		return false;
	});

	$('.datatable').each(function () {
		let $oneTable = $(this);

		let orderColumn = $oneTable.data('order-column');
		let orderDirection = $oneTable.data('order-direction');

		if (!orderColumn) orderColumn = 0;
		if (!orderDirection) orderDirection = 'asc';



		$oneTable.DataTable({
			language: {
				url: 'https://cdn.datatables.net/plug-ins/1.13.5/i18n/ru.json',
			},
			order: [orderColumn, orderDirection],
			responsive: true
		});
	});

	$('.flexy-table').each(function() {

		let $that = $(this);
		let ajax = $that.data('ajax');
		let serverSide = $that.data('serverside');
		if (!serverSide) serverSide = false;

		let columnData = [];

		let page = flexyCookie.get("datatable_page");
		let pageLength = flexyCookie.get("datatable_page_length");
		if (!page) page = 0;
		if (!pageLength || pageLength < 1) pageLength = 10;

		$that.find("thead th").each(function(){
			let data = {
				searchable: $(this).data('searchable'),
				orderable: $(this).data('orderable'),
				type: $(this).data('type'),
			};
			columnData.push(data);
		});

		//Проверяем, нужны ли чекбоксы
		let needCheckbox = $that.data('checkboxes');
		if (typeof(needCheckbox) == 'undefined') needCheckbox = false;

		let options = {
			language: {
				url: '/public/assets/common/datatables/russian.json',
			},
			ajax: {
				url: ajax,
				dataSrc: "data"
			},
			columns: columnData,
			serverSide: serverSide,
			pageLength: pageLength,
			displayStart: page * pageLength,

			lengthMenu: [
				[ 10, 25, 50, 1000 ],
				[ '10 строк', '25 строк', '50 строк', 'Все' ]
			],

			buttons: [
				{
					extend: 'collection',
					text: '<i class="fas fa-file-export"></i>',
					buttons: [
						'csv',
						'excel',
						'pdf',
						'print',
						'copy'
					],
					fade: false
				},
				'pageLength'
			],
			dom: "<'row'<'col-12'f><'col'r>><'row'<'col't>><'row'<'col'B><'col'pi>>",
		};



		if (needCheckbox) options = {
			language: {
				url: '/public/assets/common/datatables/russian.json',
				buttons: {
					selectAll: "<i class=\"far fa-check-square\"></i>",
					selectNone: "<i class=\"far fa-square\"></i>",
				}
			},
			ajax: {
				url: ajax,
				dataSrc: "data"
			},
			columns: columnData,
			serverSide: serverSide,

			pageLength: pageLength,

			displayStart: page * pageLength,

			columnDefs: [ {
				orderable: false,
				className: 'select-checkbox',
				targets:   0
			} ],
			select: {
				style:    'multi',
				selector: 'td:first-child',
				info: false,
				items: 'row',
			},

			lengthMenu: [
				[ 10, 25, 50, 1000 ],
				[ '10 строк', '25 строк', '50 строк', 'Все' ]
			],

			buttons: [
				'selectAll',
				'selectNone',
				{
					extend: 'collection',
					text: '<i class="fas fa-file-export"></i>',
					buttons: [
						'csv',
						'excel',
						'pdf',
						'print',
						'copy'
					],
					fade: false
				},
				'pageLength'
			],
			dom: "<'row'<'col-12'f><'col'r>><'row'<'col't>><'row'<'col'B><'col'pi>>",
		};

		dataTable = $that.DataTable(options);

		$that.on( 'page.dt', function () {
			let info = dataTable.page.info();
			let page = info.page;
			flexyCookie.set("datatable_page", page);
		});

		$that.on( 'length.dt', function ( e, settings, len ) {
			flexyCookie.set("datatable_page_length", len);
			flexyCookie.set("datatable_page", 0);
		} );
	});



	$('.product-plate ul.colors li a, .product-card ul.colors li a').click(function()
	{
		let plate = $(this).closest('.product-plate, .product-card');
		let image = $(this).data('image');
		let fullimage = $(this).data('fullimage');
		
		plate.find('img').attr('src', image);
		
		//Устанавливаем ссылку
		let ref = $(this).data('ref');
		plate.find('a').attr('href', ref);

		return false;
	});



	$('.control-button a').click(function()
	{
		if ($(this).hasClass('disabled')) return false;
		
		$('.control-button a').removeClass('disabled');
		$(this).addClass('disabled');
		
		if ($(this).hasClass('compress')) {
			$('.product-list.compact').show();
			$('.product-list.full').hide();
		} else {
			$('.product-list.compact').hide();
			$('.product-list.full').show();
		}

		return false;
	});



	jQuery.ajax('/current-profile', {
		type: "GET",
		contentType: "application/json; charset=utf-8",
		success: function(data, status, xhr) {
			if (data.user) {
				setUserPanel(data.user);
			} else {
				setUserPanel(false);
			}
		},
		error: function(jqxhr, textStatus, errorThrown) {
		},
		xhrFields: {
			withCredentials: true
		},
		crossDomain: true
	});

	
	$('.usermailref').click(function()
	{
		$('.topline .userfound .userpanel').toggleClass('opened');
		// $('.topline .userfound .userpanel').toggle('slide', {direction: 'up'}, 100);
		return false;
	});
	
	$(document).click(function(e)
	{
		let elem = $(".topline .userfound .userpanel");
		if (e.target!=elem[0]&&!elem.has(e.target).length)
		{
			$('.topline .userfound .userpanel').removeClass('opened');
			// elem.hide('slide', {direction: 'up'}, 50);
		} 
	});
	

	
	function setUserPanel(user)
	{
		if (user) {
			$('.topline .userfound .usermail').html(user.email);
			$('.topline .userfound .panel-username').html(user.fio);
			$('.topline .userfound .panel-balance span').html('');
			$('.topline .userfound a.usermailref').attr('title', user.fio);

			if (user.impersonated) {
				$('.topline .userfound .panel-exit').hide();
				$('.topline .userfound .panel-return').show();
			} else {
				$('.topline .userfound .panel-exit').show();
				$('.topline .userfound .panel-return').hide();
			}

			$('.topline .userfound').show();
			$('.topline .usernull').hide();
		} else {
			$('.topline .userfound').hide();
			$('.topline .usernull').show();
		}
	}

	$('.ns-form input[name=shownsserver]').prop('checked', false);
	$('.ns-form input[name=dns]').val('');
	$('.ns-form input[name=shownsserver]').click(function()
	{
		if ($('.ns-form input[name=shownsserver]').prop('checked')) {
			$('.ns-form input[name=dns]').show();
		} else {
			$('.ns-form input[name=dns]').hide();
		}
	});



    
    $('.ssl-filters input[type=checkbox]').change(filterSSL);
    
    function filterSSL()
    {
        //Собираем включенные фильтры
        let filters = {};
        
        for(let i = 0; i < $(".ssl-filters input[type=checkbox]:checked").length; i++) {
            let obj = $($(".ssl-filters input[type=checkbox]:checked")[i]);
            let filter = obj.data('filter');
            let value = obj.data('value');

            if (filters[filter] === undefined) {
				filters[filter] = [];
			}
            
            filters[filter].push(value);
        }
        
        if (filters.type == undefined || filters.type.length == 0) filters.type = ['ph', 'ur'];
        if (filters.domain == undefined || filters.domain.length == 0) filters.domain = ['1d', 'wildcard'];
        if (filters.validation == undefined || filters.validation.length == 0) filters.validation = ['DV', 'OV', 'EV'];
        
        //Получился объект вида filters.фильтр = [значения]
        //Если все флажки сняты, считаем их установленными
        $('.ssl-tarifs li').each(function()
        {
            let type = $(this).data('type');
            let domain = $(this).data('domain');
            let validation = $(this).data('validation');
            
            if (
                filters.type.indexOf(type) === -1 ||
                filters.domain.indexOf(domain) === -1 ||
                filters.validation.indexOf(validation) === -1
            
            ) $(this).hide();
            else $(this).show();
        });
    }
    
    
    
	$(window).scroll(function()
	{
		let s = $(window).scrollTop();
		if (s > 35) $('body').addClass('fixednavbar'); else $('body').removeClass('fixednavbar');
	});


	$("#infosearch").keyup(ajaxSearch);
	$("#infosearch").change(ajaxSearch);


	let searchHandler = null;
	function ajaxSearch()
	{
		let string = $("#infosearch").val();
		if (string.length < 2) return;
		
		if (searchHandler != null) searchHandler.abort();
		searchHandler = $.ajax({
                method: 'get',
                url: '/infosearch',
                data: {string: string}
            })
            .done(function(data)
			{
				$('.infolist').html(data);
			});
	}



	
	$('.slider-wrapper').slick({
		arrows: false,
		dots: false,
		autoplay: true,
//		autoplay: false,
	});
	
	let bkg = $('.slider-wrapper .slick-current div div').first().data('background');

	$('.slider-wrapper').css('background-image', "url('/public/index-slider/bkg/" + bkg +".jpg')");
	
	
	$('.slider-wrapper').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	  let current = $('.slider-wrapper .slick-slide').eq(currentSlide + 1);
	  let next = $('.slider-wrapper .slick-slide').eq(nextSlide + 1);
	  
	  let currentBkg = current.data('background');
	  let nextBkg = next.data('background');

	  if (currentBkg != nextBkg) {
		  $('.slider-wrapper').css('background-image', "url('/public/index-slider/bkg/" + nextBkg +".jpg')");
	  }
	  
	});


	$('ul.clients li span, a.hint, .ssl-tarifs li .price-plate span').tooltip({
		placement: "auto",
        html: true
	});
	
	$('a.collapse').click(function() {
		$('ul.clients').toggleClass('collapsed');
		return false;
	});
	
	$('a.scrollref').click(function() {
		let elementClick = $(this).attr("href").replace('#', '');
		let destination = $('a[name="'+elementClick+'"]').offset().top - 100;
		$('html, body').animate( { scrollTop: destination }, 600 );
		
		return false;
	});
	
	let pswpElement = document.querySelectorAll('.pswp')[0];

	let items = Array();
	$('.zoom-ref').each(function()
	{
		let ref = $(this).siblings('a.site-ref').attr('href');
		items.push({
			src: $(this).attr('href'),
			w: $(this).data('width'),
			h: $(this).data('height'),
			title: $(this).siblings('span.text').html().replace('<br><br>', ', ').replace('<br>', ', ') + '<br><a target=_blank href="'+ref+'">'+ref+'</a>',
		});
	});

	$('.zoom-ref').click(function()
	{
		let slideNumber = $(this).data('counter');
		let gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, {index: slideNumber});
		gallery.init();
		return false;
	});	
	
	
	
	
    
	$('a.order').click(function () 
    {
		return true;
        let checkblock = $(this).closest('.checksblock');
		if (checkblock.length == 0) checkblock = $('.checksblock');

		let error = false;
		if (checkblock.find('input[name=ofertaaccept]:checked').length == 0) {
			checkblock.find('input[name=ofertaaccept]').closest('label').addClass('error');
			error = true;
		} else checkblock.find('input[name=ofertaaccept]').closest('label').removeClass('error');

		if (checkblock.find('input[name=privateaccept]:checked').length == 0) {
			checkblock.find('input[name=privateaccept]').closest('label').addClass('error');
			error = true;
		} else checkblock.find('input[name=privateaccept]').closest('label').removeClass('error');
		if (error) return false;
		return true;
	});

	$('a.popupmenuref').click(function () {
		if ($(this).hasClass('cross')) {
			$('header nav.wrapper').hide('fade', 100);
			$('.overlay').hide();
			$(this).removeClass('cross');
		} else {
			$('header nav.wrapper').show('fade', 100);
			$('.overlay').show();
			$(this).addClass('cross');
		}
		return false;
	});

	$('.overlay').click(function () {
		$('header nav.wrapper').hide('fade', 100);
		$('.overlay').hide();
		$('a.popupmenuref').removeClass('cross');
		return false;
	});

	$(window).resize(resizeWindows);

	function resizeWindows() {
		let width = $(window).width();
		if (width > 600) {
			$('header nav.wrapper').show();
			$('.overlay').hide();
			$('a.popupmenuref').removeClass('cross');
		} else {
			$('header nav.wrapper').hide();
			$('.overlay').hide();
			$('a.popupmenuref').removeClass('cross');
		}
	}

	$('input[name=ofertaaccept]').click(function () {
		$(this).closest('label').removeClass('error');
	});

	$('input[name=privateaccept]').click(function () {
		$(this).closest('label').removeClass('error');
	});



	if ($('input[name=domain]').val() !== '') {
		let $form = $('input[name=domain]').closest('form');

		if($form.length) {
			checkDomain($form);
		}
	}

	$('.checkdomain input[type=submit], .checkdomain a.button').click(function () {
		let $form = $(this).closest('form');
		checkDomain($form);
		return false;
	});

	function checkDomain($form)
	{
		let href = $form.attr('action');
		let params = {
			domain: $form.find('input[name=domain]').val(),
		};
		$('.domain-result .message').html('<img src="/public/build/img/preloader.gif" />');
		$.get(
			href,
			params,
			function (data) {
				if (data.error == '1') {
					$('.domain-result .message').html(data.message);
					$('.domain-result .order-plate').hide();
				} else {
					let text = data.message;
					text = text + "<div class=domain-price>Стоимость домена в зоне " + data.zone + ": <b>" + data.price + "</b> руб. в год</div>";
					let ref = 'https://billing.flexy-reg.ru/billmgr?func=register&redirect=startpage%3Ddomain%26startform%3Ddomain%252Eorder%26pricelist%3D5%26project%3D1%26selected_pricelist%3D' + data.pricelist + '%26domain_name%3D' + data.domain;
					$('.domain-result .order-plate a.order').attr('href', ref);
					$('.domain-result .message').html(text);
					$('.domain-result .order-plate').show();
				}
			}, 'json');
	}




	$('.ns-form input[type=submit], .ns-form a.button').click(function () {
		let href = $(this).closest('form').attr('action');
		let params = {
			domain: $(this).closest('form').find('input[name=domain]').val(),
			nstype: $(this).closest('form').find('select[name=nstype]').val(),
			shownsserver: $(this).closest('form').find('input[name=shownsserver]').prop('checked'),
			dns: $(this).closest('form').find('input[name=dns]').val()
		};
		$('.domain-result .message').html('<img src="/public/build/img/preloader.gif" />');
        
		$.post(
			href,
			params,
			function (data) {
				if (data.error == '1') {
					$('.domain-result .message').html(data.message);
				} else {
					$('.domain-result .message').html(data.message);
				}
			}, 'json');
		return false;
	});
    
    
    
    
    //Работа с шаблонами
    $('.tm_filter select, .tm_filter input').change(setTmPlates);
    $('.tm_filter .tm_color-plate a').click(function()
    {
        $('.tm_filter .tm_color-plate li').removeClass('selected');
        $(this).closest('li').addClass('selected');
        return setTmPlates();
    });
    
    setTmPlates();
    
    let isLoading = false;
    function setTmPlates()
    {
		if ($(".tm_plates").length == 0) return false;
        //Собираем фильтры
        let color = 'all';
        let category = 'all';
        let keywords = '';
        let sort = 'date desc';
        
        if ($('.tm_filter .tm_color-plate li.selected a').length != 0)
        {
            let color = $('.tm_filter .tm_color-plate li.selected a').data('color');
        }
        keywords = $('.tm_filter [name=keywords]').val();
        category = $('.tm_filter [name=category] option:selected').val();
        sort = $('.tm_filter [name=sort] option:selected').val();
        
        //Отправляем запрос на сервер
        if (!isLoading)
        {
            isLoading = true;
            $(".tm_plates").html('<img src="/public/build/img/preloader.gif" />');
            $.ajax({
                method: 'post',
                url: '/ajax.gettemplates',
                data: {color: color, category: category, keywords: keywords, sort: sort}
            })
            .done(function(data)
            {
                if (data.templates == undefined) return;
                $(".tm_plates").html('');
                for(let i = 0; i< data.templates.length; i++)
                {
                    $(".tm_plates").append(
"<li>\
    <div class=plate-wrapper>\
        <div class=plate href='#' style='background-image: url(\""+ data.templates[i].screenshot +"\");'></div>\
    </div>\
\
        <div class=tm__price>\
            " + data.templates[i].price + " р.\
            <div class=tm__id>#" + data.templates[i].id + "</div>\
        </div>\
\
        <a class=tm__details href='#'>Подробнее</a>\
</li>"
                    );
                }
            })
            .fail(function()
            {
                $(".tm_plates").html('');
                alert("Произошла ошибка, перезагрузите страницу и попробуйте ещё раз.")
            })
            .always(function()
            {
                isLoading = false;
            })
        }
        
        return false;
    }
});

//Функция перевода названия объекта в ссылку с корректными символами
function translite(string) {
	let dictionary = {
		'а':'a', 'б':'b', 'в':'v', 'г':'g', 'д':'d', 'е':'e', 'ж':'g', 'з':'z', 'и':'i', 'й':'y', 'к':'k', 'л':'l', 'м':'m', 'н':'n', 'о':'o', 'п':'p', 'р':'r', 'с':'s', 'т':'t', 'у':'u', 'ф':'f', 'ы':'y', 'э':'e', 'А':'A', 'Б':'B', 'В':'V', 'Г':'G', 'Д':'D', 'Е':'E', 'Ж':'G', 'З':'Z', 'И':'I', 'Й':'Y', 'К':'K', 'Л':'L', 'М':'M', 'Н':'N', 'О':'O', 'П':'P', 'Р':'R', 'С':'S', 'Т':'T', 'У':'U', 'Ф':'F', 'Ы':'Y', 'Э':'E', 'ё':'yo', 'х':'h', 'ц':'ts', 'ч':'ch', 'ш':'sh', 'щ':'shch', 'ъ':'', 'ь':'', 'ю':'yu', 'я':'ya', 'Ё':'YO', 'Х':'H', 'Ц':'TS', 'Ч':'CH', 'Ш':'SH', 'Щ':'SHCH', 'Ъ':'', 'Ь':'','Ю':'YU', 'Я':'YA',
		'a':'a', 'b':'b', 'c':'c', 'd':'d', 'e':'e', 'f':'f', 'g':'g', 'h':'h', 'i':'i', 'j':'j', 'k':'k', 'l':'l', 'm': 'm',
		'n':'n', 'o':'o', 'p':'p', 'q':'q', 'r':'r', 's':'s', 't':'t', 'u':'u', 'v':'v', 'w':'w', 'x':'x', 'y':'y', 'z': 'z',

		'A':'A', 'B':'B', 'C':'C', 'D':'D', 'E':'E', 'F':'F', 'G':'G', 'H':'H', 'I':'I', 'J':'J', 'K':'K', 'L':'L', 'M':'M',
		'N':'N', 'O':'O', 'P':'P', 'Q':'Q', 'R':'R', 'S':'S', 'T':'T', 'U':'U', 'V':'V', 'W':'W', 'X':'X', 'Y':'Y', 'Z':'Z',

		'0':'0', '1':'1', '2':'2', '3':'3', '4':'4', '5':'5', '6':'6', '7':'7', '8':'8', '9':'9', ' ':'-'
	};

	let res = string.replace(/[\s\S]/g, function(x)
	{
		if( dictionary.hasOwnProperty( x ) )  return dictionary[ x ];
		return '';
	});

	res = res.replace('--', '-');
	res = res.replace('--', '-');
	res = res.replace('--', '-');
	return res;
}

function encrypt(obj) {
	let json = JSON.stringify(obj);
	let encoded = btoa(json);
	// Заменим символы "+" на "-", а "/" на "_"
	return encoded.replace(/\+/g, "-").replace(/\//g, "_");
}


function getProlongLink() {
	let data = collectData();
	return '?data=' + encrypt(data);
}


function collectData()
{
	// Собираем данные
	let services = [];
	$('.prolong-table input[type=checkbox]').each(function() {
		if ($(this).prop('checked')) {
			services.push({
				type: $(this).data('type'),
				id: $(this).data('id')
			});
		}
	});

	let prolongMethod = null;
	$('.prolong-method input[type=radio]').each(function() {
		if ($(this).is(':checked')) {
			prolongMethod = $(this).prop('value');
		}
	});

	return {
		services: services,
		prolongType: prolongMethod
	};
}
